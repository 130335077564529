import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "@/page/login";
import Index from "@/page/index";
import Form from "@/page/form";
import Status from "@/page/status";

const setTitle = (title) => {
  document.title = title;
};
const RouterList = [
  { title: "登录", path: "/login", component: Login },

  {
    title: "加入骑手",
    path: "/",
    component: Index,
    requiresAuth: true,
  },
  {
    title: "加入骑手-实名认证",
    path: "/form",
    component: Form,
    requiresAuth: true,
  },
  {
    title: "信息审核",
    path: "/status",
    component: Status,
    requiresAuth: true,
  },
];
// 需要token权限的路由
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      setTitle(rest.title);
      return Cookies.get("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
const RouteItemDom = (item) => {
  return item.requiresAuth ? (
    <PrivateRoute
      exact
      path={item.path}
      key={item.path}
      title={item.title}
      component={item.component}
    />
  ) : (
    <Route
      exact
      path={item.path}
      key={item.path}
      title={item.title}
      render={(props) => {
        setTitle(item.title);
        return <item.component {...props} />;
      }}
    />
  );
};

const BasicRoute = () => (
  <BrowserRouter>
    <Switch>
      {/* exact 严格模式，统一开启 */}
      {RouterList.map((item) => RouteItemDom(item))}
    </Switch>
  </BrowserRouter>
);

export default BasicRoute;
