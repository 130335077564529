import gql from "graphql-tag";

export const ADD_RUNNER = gql`
  mutation (
    $area: [String]
    $province: String
    $city: String
    $name: String
    $id_card: String
    $id_card_front: String
    $id_card_back: String
  ) {
    addRunner(
      area: $area
      name: $name
      id_card: $id_card
      id_card_front: $id_card_front
      id_card_back: $id_card_back
      province: $province
      city: $city
    )
  }
`;
