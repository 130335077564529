import client from "@/graphql/apollo-config";
import { GET_RUNNER } from "@/graphql/query";
import Cookies from "js-cookie";
import React from "react";

import { useHistory } from "react-router";

import "./index.less";

const statusConfig = {
  2: {
    image: require("@/assets/images/status-waiting.png"),
    color: "#ABABAB",
    text: "您的实名认证正在审核中\n请耐心等待......",
  },
  1: {
    image: require("@/assets/images/status-sucess.png"),
    color: "#E79C3F",
    text: "您已入驻成功\n恭喜您成为典栈骑手!",
  },
  3: {
    image: require("@/assets/images/status-refusedTo.png"),
    color: "#E52828",
    text: "审核未通过\n",
  },
};

function Status() {
  const state = Cookies.get("runnerStatus");
  const history = useHistory();

  const [runner, setRunner] = React.useState(null);

  React.useEffect(() => {
    client
      .mutate({
        mutation: GET_RUNNER,
        fetchPolicy: "no-cache",
      })
      .then(({ data: { runner } }) => {
        setRunner(runner);
      });
  }, []);

  return (
    <div className="status container">
      <img className="status-img" src={statusConfig[state].image} alt="" />
      <div
        className="status-text"
        style={{
          color: statusConfig[state].color,
        }}
      >
        {statusConfig[state].text + (state === "3" ? runner?.reason : "")}
      </div>
      {state === "3" && (
        <div className="status-btn" onClick={() => history.replace("/form")}>
          重新提交
        </div>
      )}
    </div>
  );
}

export default Status;
