import React, { useState, useEffect } from "react";
import { Toast } from "antd-mobile";

import Cookies from "js-cookie";
import { useMutation } from "react-apollo";
import { LOGIN, SEND_SMS_CAPTCHA } from "@/graphql/login";
import { useHistory } from "react-router-dom";
import { isPhone } from "@/utils";
import { captcha } from "@/utils/captcha";
import useInterval from "@/utils/useInterval";

import "./index.less";

const LoginComponents = () => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [value, setValue] = useState();
  const [codeSecond, setCodeSecond] = useState(0);

  const [loginMutation] = useMutation(LOGIN, {
    variables: {
      phone: phone,
      code: code,
      codeType: 1,
    },
    onCompleted: async (data) => {
      Cookies.set("token", data.login.remember_token);
      Cookies.set("mobile", data.login.mobile);
      setLoading(false);
      window.location = "/";
    },
    // 请求失败异常的回调
    onError(e) {
      setLoading(false);
    },
  });

  useInterval(() => {
    if (codeSecond > 0) {
      setCodeSecond(codeSecond - 1);
    }
  }, 1000);

  const confirmLogin = () => {
    if (loading) {
      return;
    }
    if (!phone) {
      Toast.info("请输入手机号");
    } else if (!isPhone(phone)) {
      Toast.info("手机号码格式不正确，请重新输入");
    } else if (!code) {
      Toast.info("请输入验证码");
    } else {
      setLoading(true);
      loginMutation();
    }
  };

  const toCaptcha = () => {
    captcha(() => {
      let captchaIns;
      window.initNECaptcha(
        {
          element: "#captcha",
          captchaId: "3a0bc0da0f9449858140422b0a128f80",
          mode: "popup",
          width: "320px",
          enableClose: false,
          feedbackEnable: false,
          onVerify: (_, data) => {
            if (data && data.validate) {
              setValue(data.validate);
              sendSmsCaptcha();
              setCodeSecond(60);
            }
          },
        },
        (instance) => {
          captchaIns = instance;
          captchaIns && captchaIns.popUp();
        },
        (err) => {}
      );
    });
  };

  const [sendSmsCaptcha] = useMutation(SEND_SMS_CAPTCHA, {
    onCompleted(data) {
      if (data.captcha) {
        setCodeSecond(60);
      }
    },
    // 请求失败异常的回调
    onError(e) {
      clearInterval(timer);
      setCodeSecond(0);
      if (e.graphQLErrors instanceof Array && e.graphQLErrors.length > 0) {
        e.graphQLErrors.forEach((err) => {});
      }
    },
    // 请求带的参数
    variables: {
      mobile: phone,
      type: 1,
      value: value,
    },
  });

  const getSmsCode = () => {
    if (!codeSecond) {
      if (!phone) {
        Toast.info("请输入手机号");
      } else {
        toCaptcha();
      }
    }
  };

  return (
    <div className="login">
      <input
        type="text"
        placeholder="请输入手机号"
        maxLength="11"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <div className="verifica-code">
        <input
          type="text"
          name="code"
          autoComplete="off"
          placeholder="请输入验证码"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <input
          type="password"
          autoComplete="new-password"
          style={{ display: "none" }}
        />
        <div onClick={getSmsCode} className="code">
          <span className="href">
            {!!codeSecond ? codeSecond + "s后重新获取" : "获取验证码"}
          </span>
        </div>
      </div>
      <div className="btn" onClick={confirmLogin}>
        立即登录
      </div>
    </div>
  );
};

let timer = "";

const Login = () => {
  const { push } = useHistory();

  useEffect(() => {
    const { token = "", route = "" } = window.location.search
      .replace("?", "")
      .split("&")
      .reduce((pre, cur) => {
        const itemData = cur.split("=");
        pre[itemData[0]] = itemData[1];
        return pre;
      }, {});
    if (token) {
      Cookies.set("token", token);
      switch (route) {
        case "basicInfo": //网商认证
          window.location = "/basicInfo";
          break;
        case "setcommission": //从App过来设置佣金规则
          window.location = "/setcommission";
          break;
        default:
          window.location = "/";
          break;
      }
    }
  }, []);

  return (
    <div className="sign">
      <div id="captcha" />
      <article className="sign-wrapper">
        <section className="sign-card">
          <LoginComponents />
        </section>
      </article>
      <footer className="sign-footer">
        <div className="sign-footer_text">
          登录代表您已同意
          <div className="sign-footer_text_theme">
            典栈
            <span onClick={() => push(`/resources?name=store_agreement`)}>
              用户协议
            </span>
            、
            <span
              onClick={() => push(`/resources?name=store_privacy_agreement`)}
            >
              隐私协议
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Login;
