import gql from "graphql-tag";
// 用户登录
export const LOGIN_STORE = gql`
  mutation loginStore($phone: String!, $password: String!, $pos_code: String) {
    loginStore(phone: $phone, password: $password, pos_code: $pos_code) {
      remember_token
      id
      type
      mobile
      store {
        id
        product_type
      }
    }
  }
`;

// 发送短信验证码
export const SEND_SMS_CAPTCHA = gql`
  mutation captcha($mobile: String!, $type: Int!, $value: String!) {
    captcha(mobile: $mobile, type: $type, value: $value)
  }
`;
// 创建图形验证码
export const GET_IMAGE_CAPTCHA = gql`
  query captchaImg {
    captchaImg {
      key
      img
    }
  }
`;

// 省市区查询
export const REGION = gql`
  query {
    region {
      id
      name
      data {
        id
        name
        data {
          id
          name
        }
      }
    }
  }
`;

export const GET_OSS = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
      ossUrl
    }
  }
`;

// 图片识别
export const IMAGE2TEXT = gql`
  query ($url: String!) {
    image2Text(type: 0, url: $url) {
      name
      id_card_no
    }
  }
`;

export const GET_RUNNER = gql`
  query {
    runner {
      status
      reason
    }
  }
`;
