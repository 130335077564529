import React from "react";
import Uploader from "@/components/uploader";
import { Picker, InputItem, Icon, Checkbox, Flex, Toast } from "antd-mobile";
import { REGION, IMAGE2TEXT } from "@/graphql/query";
import { useQuery } from "react-apollo";
import { ADD_RUNNER } from "@/graphql/mutation";
import { useHistory } from "react-router";

import "./index.less";
import client from "@/graphql/apollo-config";
import { handleImg } from "@/utils";
import Cookies from "js-cookie";

const checkForm = (obj) => {
  for (let i in obj) {
    if (i === "area") {
      if (
        !obj[i] ||
        !obj[i].length ||
        (obj[i][2] && !obj[i][obj[i].length - 1].length)
      ) {
        return false;
      }
    } else if (!obj[i]) {
      return false;
    }
  }
  return true;
};

function Form() {
  const regionRes = useQuery(REGION, {
    fetchPolicy: "no-cache",
  });

  const [pickerShow, setPickerShow] = React.useState(false);
  const [addressValue, setAddressValue] = React.useState([]);
  const [areaList, setAreaList] = React.useState([]);
  const [formData, setFormData] = React.useState({
    id_card_front: null,
    id_card_back: null,
    id_card: "",
    name: "",
    area: null,
  });

  const canSubmit = checkForm(formData);

  const history = useHistory();

  return (
    <div className="form">
      <div className="title">请拍摄身份证正反面照片</div>
      <div className="form-item image">
        <div className="form-item-upload">
          <Uploader
            extra="身份证国徽面"
            value={
              !formData.id_card_front
                ? []
                : [
                    {
                      url: formData.id_card_front,
                    },
                  ]
            }
            onChange={(files) => {
              setFormData({
                ...formData,
                id_card_front: files.length ? files[files.length - 1].url : "",
              });
            }}
          />
        </div>
        <div className="form-item-upload">
          <Uploader
            extra="身份证人像面"
            value={
              !formData.id_card_back
                ? []
                : [
                    {
                      url: formData.id_card_back,
                    },
                  ]
            }
            onChange={(files) => {
              if (files.length) {
                client
                  .query({
                    query: IMAGE2TEXT,
                    variables: {
                      url: handleImg(files[files.length - 1].url),
                    },
                  })
                  .then(
                    ({
                      data: {
                        image2Text: { name, id_card_no },
                      },
                    }) => {
                      setFormData({
                        ...formData,
                        name,
                        id_card: id_card_no,
                        id_card_back: files[files.length - 1].url,
                      });
                    }
                  )
                  .catch((err) => {
                    setFormData({
                      ...formData,
                      id_card_back: files[files.length - 1].url,
                    });
                  });
              } else {
                setFormData({
                  ...formData,
                  id_card_back: "",
                });
              }
            }}
          />
        </div>
      </div>
      <div className="title">请确认以下信息</div>
      <InputItem
        placeholder="请输入姓名"
        value={formData.name}
        onInput={({ target: { value } }) => {
          setFormData({
            ...formData,
            name: value,
          });
        }}
      >
        姓名：
      </InputItem>
      <InputItem
        value={formData.id_card}
        placeholder="请输入身份证号"
        onInput={({ target: { value } }) => {
          setFormData({
            ...formData,
            id_card: value,
          });
        }}
      >
        身份证号：
      </InputItem>

      <div className="title">填写工作地区</div>
      <div className="form-item">
        <div className="form-item-label">意向工作地区：</div>
        <div
          className="form-item-input picker"
          onClick={() => setPickerShow(true)}
        >
          <div className={`${addressValue.length ? "active" : ""}`}>
            {addressValue.length ? addressValue.join("-") : "请选择省/市"}
          </div>
          <Icon type="right" />
        </div>
      </div>
      {Boolean(areaList.length) && (
        <div className="form-item">
          <Flex wrap="wrap">
            {areaList.map((item) => (
              <Checkbox.CheckboxItem
                key={item.name}
                value={item.name}
                onChange={({ target: { checked } }) => {
                  const areaList = formData.area;
                  const index = areaList[2].findIndex(
                    (area) => area === item.name
                  );
                  if (!checked) {
                    areaList[2].splice(index, 1);
                  } else {
                    areaList[2].push(item.name);
                  }
                  setFormData({
                    ...formData,
                    area: areaList,
                  });
                }}
              >
                {item.name}
              </Checkbox.CheckboxItem>
            ))}
          </Flex>
        </div>
      )}

      <Picker
        data={(regionRes.data?.region || []).map((item) => {
          return {
            label: item.name,
            value: item.name,
            children: item.data.map((city) => ({
              label: city.name,
              value: city.name,
            })),
          };
        })}
        visible={pickerShow}
        onDismiss={() => {
          setPickerShow(false);
        }}
        cols={2}
        value={addressValue}
        onOk={(value) => {
          setAddressValue(value);
          setAreaList(
            regionRes.data.region
              .find((item) => item.name === value[0])
              .data.find((item) => item.name === value[1]).data
          );
          setFormData({
            ...formData,
            area: [...value, []],
          });
          setPickerShow(false);
        }}
      />
      <div
        className={`submitBtn ${canSubmit ? "" : "disabled"}`}
        onClick={() => {
          if (canSubmit) {
            Toast.loading("提交中...");
            client
              .mutate({
                mutation: ADD_RUNNER,
                variables: {
                  ...formData,
                  province: formData.area[0],
                  city: formData.area[1],
                  area: formData.area[2],
                },
              })
              .then(() => {
                Toast.success("提交成功");
                Cookies.set("runnerStatus", 2);
                setTimeout(() => {
                  history.replace("/status");
                }, 500);
              });
          }
        }}
      >
        提交
      </div>
    </div>
  );
}

export default Form;
