import React, { useEffect } from "react";
import { ImagePicker, Toast } from "antd-mobile";

import "./index.less";
import { useQuery } from "react-apollo";
import { GET_OSS } from "@/graphql/query";

const upload = (url, from, callback) => {
  var xhr = new XMLHttpRequest();
  xhr.open("post", url, true);
  xhr.onreadystatechange = () => {
    callback(xhr);
  };
  xhr.send(from);
};

function Uploader(props) {
  const { onChange, length = 1, value, extra, ...extProps } = props;
  const [fileList, setFileList] = React.useState([]);
  const ossRes = useQuery(GET_OSS, {
    fetchPolicy: "no-cache",
  });
  const OSSData = ossRes.data?.oss || {};

  useEffect(() => {
    value && setFileList(value);
  }, [value]);

  const uploadOss = async (file) => {
    return new Promise((resolve) => {
      Toast.loading("上传中...");
      const filename = new Date().getTime() * 2020;
      const request = new FormData();
      request.append("OSSAccessKeyId", OSSData.accessid);
      request.append("policy", OSSData.policy);
      request.append("Signature", OSSData.signature);
      request.append(
        "key",
        `${OSSData.dir}${filename}.${file.file.type.split("/")[1]}`
      );
      request.append("file", file.file);
      upload(`https://${OSSData.host}`, request, (res) => {
        Toast.hide();
        if (res.readyState === 4 && res.status >= 200 && res.status < 400) {
          resolve([
            ...fileList,
            {
              url: `${OSSData.ossUrl + OSSData.dir + filename}.${
                file.file.type.split("/")[1]
              }`,
            },
          ]);
        }
      });
    });
  };
  return (
    <div className="upload">
      <ImagePicker
        files={fileList}
        selectable={length !== fileList.length}
        length={length}
        onChange={async (files, operationType) => {
          if (operationType === "remove") {
            setFileList(files);
            onChange && onChange(files);
            return;
          }
          if (operationType !== "add" || files.length === 0) {
            return;
          }

          if (files.length) {
            const newFileList = await uploadOss(files[files.length - 1]);
            setFileList(newFileList);
            onChange && onChange(newFileList);
          }
        }}
        {...extProps}
      />
      {extra && length !== fileList.length && (
        <div className="upload-extra">{extra}</div>
      )}
    </div>
  );
}

export default Uploader;
