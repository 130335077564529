import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./graphql/apollo-config";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
  ,
  document.getElementById("root")
);
//热更新
if(module.hot){
  module.hot.accept()
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
