import React from "react";
import { useHistory } from "react-router";
import Cookies from "js-cookie";
import client from "@/graphql/apollo-config";
import { GET_RUNNER } from "@/graphql/query";

import "./index.less";

function Index() {
  const history = useHistory();

  const [runnerStatus, setRunnerStatus] = React.useState(null);

  React.useEffect(() => {
    client
      .mutate({
        mutation: GET_RUNNER,
        fetchPolicy: "no-cache",
      })
      .then(({ data: { runner } }) => {
        const status = runner ? runner.status : null;
        Cookies.set("runnerStatus", status);
        setRunnerStatus(status);
      });
  }, []);

  return (
    <div className="index">
      <img
        src={require("@/assets/images/riderBg.png")}
        alt=""
        className="index-bg"
      />
      <div
        className="submitBtn"
        onClick={() => {
          if (runnerStatus) {
            history.push(`/status?state=${runnerStatus}`);
          } else {
            history.push("/form");
          }
        }}
      >
        成为骑手
      </div>
    </div>
  );
}

export default Index;
