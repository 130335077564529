import React from "react";
import { withRouter } from "react-router";
import "./app.less";
import "normalize.css";
import Router from "./router";
import "lib-flexible";

// 函数组件 app

const App = (props) => {
  return (
    <div className="container">
      <Router />
    </div>
  );
};

export default withRouter(App);
